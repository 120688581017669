/* global google,kafkaInfo,GB_route_jump */
import { daEventCenter } from 'public/src/services/eventCenter/index'
import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
import schttpSync from 'public/src/services/schttp/sync'
import { replacePaySuccessReferer } from '@/public/src/pages/common/handlePay/tools.js'
import mitt from 'mitt'
import { GOOGLE_PAY_METHODS } from './config'
import errorReport from '@/public/src/pages/common/handlePay/log'

const googlePayEventCenter = mitt()

daEventCenter.addSubscriber({ modulecode: '1-19-1' })

function makeForm (params, action, method) {
  let form = document.querySelector('form[name="pay_jump_form"]')
  if (form) {
    form.parentNode.removeChild(form)
  }

  method = method || 'post'
  form = document.createElement('form')
  form.setAttribute('name', 'pay_jump_form')
  form.setAttribute('action', action)
  form.setAttribute('method', method)

  for (const key in params) {
    let input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', key)
    input.setAttribute('value', params[key])
    form.appendChild(input)
  }

  let submitInput = document.createElement('input')
  submitInput.setAttribute('type', 'submit')
  submitInput.setAttribute('value', 'Continue')
  submitInput.style.display = 'none'
  form.appendChild(submitInput)

  document.body.appendChild(form)
  document.pay_jump_form.submit()
}

const defaultCardNetworks = [
  'AMEX',
  'DISCOVER',
  'JCB',
  'MASTERCARD',
  'VISA'
]

const defaultAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS']

const useApolloConfig = gbCommonInfo?.PAY_CONFIG_SWITCH  == 1

// TODO:debug 调试信息，测试完删除
console.log('useApolloConfig', useApolloConfig)

/**
 * GooglePay 支付逻辑
 *
 * https://developers.google.com/pay/api/web/guides/tutorial
 */
class GooglePay {
  hasCreateBtn = false
  abtGoUnify = true // 是否走后端合并接口
  valid = -1 // 浏览器是否兼容谷歌pay: -1－还没校验，１－校验通过，－0校验不通过
  pay_fail_tips = {}

  merchantId = ''
  merchantName = ''
  gatewayMerchantId = ''
  routeId = '' // 前置路由接口返回的routeId，传入统一支付接口

  apiVersion

  apiVersionMinor

  paymentsClient = null

  language = {}

  paymentCode = ''

  orderInfo = {}

  paymentMethods = []

  cardLogoMap = {}

  payConfig = null

  scene = '' // 场景 CheckoutAgain, BuyPrime, GiftCard, GiftCardAgain, Checkout

  static eventCenter = googlePayEventCenter

  static googlePayMethods = gbCommonInfo?.GOOGLE_PAY_CODE_LIST || GOOGLE_PAY_METHODS

  constructor({ scene }) {
    this.scene = scene
    this._getLanguage()
    GooglePay.clearAllListener()
  }

  _getLanguage() {
    schttp({
      url: `/api/common/language/get`,
      params: {
        page: 'googlepay_fail_tips',
      },
    }).then(({ language }) => {
      this.language = language
      this.pay_fail_tips = {
        w_7501: language.SHEIN_KEY_PC_15483,
        w_7502: language.SHEIN_KEY_PC_15484,
        w_7503: language.SHEIN_KEY_PC_15485,
        w_7504: language.SHEIN_KEY_PC_15486,
        w_7505: language.SHEIN_KEY_PC_15487,
        w_7506: language.SHEIN_KEY_PC_15488,
        w_7507: language.SHEIN_KEY_PC_15489,
        w_7508: language.SHEIN_KEY_PC_15490,
        w_7509: language.SHEIN_KEY_PC_15491,
        w_7510: language.SHEIN_KEY_PC_15492,
        w_7511: language.SHEIN_KEY_PC_15493,
        w_7512: language.SHEIN_KEY_PC_15494,
        w_7513: language.SHEIN_KEY_PC_15495,
        w_301002: language.SHEIN_KEY_PC_14787,
      }
    })
  }

  _getGatewayName() {
    if (useApolloConfig && this.payConfig?.gateway) {
      return this.payConfig.gateway
    } else {
      switch (this.paymentCode) {
        case 'adyen-googlepay':
          return 'adyen'
        case 'nuvei-googlepay':
          return 'nuveidigital'
        case 'worldpay-googlepay':
          return 'worldpay'
        default:
          break
      }
    }
  }

  _getCheckoutType() {
    const isGiftCard = ['GiftCard', 'GiftCardAgain'].includes(this.scene)
    const isBuyPrime = ['BuyPrime', 'BuyPrimeAgain'].includes(this.scene)
    const isOCP = this.scene === 'OCP'
    const isBuyXtra = ['Xtra', 'XtraAgain'].includes(this.scene)

    let checkout_type = ''
    if (isGiftCard) checkout_type = 'giftcard'
    if (isBuyPrime) checkout_type = 'buyprime'
    if (isBuyXtra) checkout_type = 'xtra'
    if (isOCP) checkout_type = 'ocp'
    return checkout_type
  }

  _getProductType () {
    const checkoutType = this._getCheckoutType()
    switch (checkoutType) {
      case 'giftcard':
        return 'gift_card'
      case 'buyprime':
        return 'paid_membership'
      case 'xtra':
        return 'paid_shein_saver'
      case 'ocp':
        return 'one_click_pay'
      default:
        return 'normal'
    }
  }

  _sendErrorReport (payment_error_scene = '', params = {
    failure_type: '',
    client_url: '',
    status_code: '',
    description: ''
  }) {
    errorReport.sendPaymentError(payment_error_scene, {
      failure_type: params?.failure_type || '',
      product_type: this._getProductType(),
      payment_action_type: 'google_pay',
      client_url: params?.client_url || '',
      error_data: {
        status_code: params?.status_code,
        uorder_id: this.orderInfo?.relation_billno,
        order_no: this.orderInfo?.billno,
        payment_method: this.paymentCode,
        description: params?.description || '',
      }
    })
  }

  _getDeviceInfo () {
    return {
      //acceptHeader: '',
      colorDepth: window.screen.colorDepth,
      javaEnabled: window.navigator.javaEnabled() ? 1 : 0,
      language: window.navigator.language,
      screenHeight: window.screen.height || 0,
      screenWidth: window.screen.width || 0,
      timeZoneOffset: new Date().getTimezoneOffset(),
      userAgent: window.navigator.userAgent,
      origin: window.location.origin
    }
  }

  /**
   * 清除所有事件
   *
   * @static
   * @memberof GooglePay
   */
  static clearAllListener() {
    if (typeof GooglePay.eventCenter === 'undefined') return
    GooglePay.eventCenter.all.clear()
  }

  // 验证是否支持google pay参数
  getGoogleIsReadyToPayRequest() {
    const apolloPayRequest = gbCommonInfo?.GOOGLE_READY_TO_PAY_REQUEST
    if (useApolloConfig && apolloPayRequest) {
      const { apiVersion = 2, apiVersionMinor = 0 } = apolloPayRequest
      this.apiVersion = apiVersion
      this.apiVersionMinor = apiVersionMinor
      return apolloPayRequest
    } else {
      return {
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [{
          type: 'CARD',
          parameters: {
            allowedAuthMethods: defaultAuthMethods,
            allowedCardNetworks: defaultCardNetworks
          }
        }]
      }
    }
  }

  _getAllowedCardNetworks() {
    if (useApolloConfig) {
      let supportsCard = []
      const { cardLogoMap = {}, originPaymentCode, paymentMethods } = this
      const currentPayment = paymentMethods?.find?.(item => item?.code === originPaymentCode)
      if (currentPayment) {
        const { card_logo_list = [], apple_google_support_config_list = [] } = currentPayment
        card_logo_list.forEach(item => {
          const cardInfo = apple_google_support_config_list?.find?.(card => card.card_type_logo === item)
          const apolloCardInfo = cardLogoMap[item]
          if (cardInfo && cardInfo.card_type) {
            supportsCard.push(cardInfo.card_type)
          } else if (!apple_google_support_config_list?.length && apolloCardInfo?.name) {
            supportsCard.push(apolloCardInfo.name)
          }
        })
      }
      if (supportsCard.length) {
        return supportsCard
      } else {
        return defaultCardNetworks
      }
    } else {
      return defaultCardNetworks
    }
  }

  _isBr (order) {
    return order.countryCode === 'BR' || order.country_id === 30
  }

  getGooglePaymentDataRequest() {
    const _this = this
    let totalPrice = _this.orderInfo.totalPrice
    // 巴西政府税特殊传值逻辑 https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1427084862
    if (_this._isBr(_this.orderInfo) && _this.paymentCode === 'adyen-googlepay' && _this.orderInfo.totalPriceWithGovTax) {
      totalPrice = _this.orderInfo.totalPriceWithGovTax
    }
    const merchantInfo = {
      merchantId: _this.merchantId, //测试环境可以不传，生产环境必须传该参数
      merchantName: _this.merchantName,
    }
    const transactionInfo = {
      totalPriceStatus: 'FINAL',
      totalPrice: '' + totalPrice,
      countryCode: _this.orderInfo.countryCode,
      currencyCode:
        _this.orderInfo.currency_code || _this.orderInfo.currencyCode,
    }

    if (useApolloConfig) {
      const { apiVersion = 2, apiVersionMinor = 0, payConfig = {} } = _this
      const { allowedAuthMethods = defaultAuthMethods, type = 'CARD' } = payConfig || {}
      const allowedCardNetworks = _this._getAllowedCardNetworks()
      return {
        apiVersion,
        apiVersionMinor,
        merchantInfo,
        allowedPaymentMethods: [
          {
            type,
            parameters: {
              allowedAuthMethods,
              allowedCardNetworks,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: _this._getGatewayName(),
                gatewayMerchantId: _this.gatewayMerchantId,
              },
            },
          }
        ],
        transactionInfo
      }
    } else {
      return {
        apiVersion: 2,
        apiVersionMinor: 0,
        merchantInfo,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: defaultAuthMethods,
              allowedCardNetworks: defaultCardNetworks,
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: _this._getGatewayName(),
                gatewayMerchantId: _this.gatewayMerchantId,
              },
            },
          },
        ],
        transactionInfo
      }
    }
  }

  getGooglePaymentsClient() {
    if (this.paymentsClient === null && typeof google !== 'undefined') {
      var testMode = ['debug', 'localhost']
      var type = testMode.indexOf(gbCommonInfo.NODE_SERVER_ENV) > -1 ? 'TEST' : 'PRODUCTION'
      this.paymentsClient = new google.payments.api.PaymentsClient({ environment: type })      //虚拟支付：'TEST'，生产环境：'PRODUCTION
    }
    return this.paymentsClient
  }

  //检测浏览器是否支持谷歌pay
  onGooglePayLoaded(options, cb) {
    return new Promise((resolve, reject) => {
      const _this = this
      const { createDom, code } = options || {}
      this.paymentCode = code || this.paymentCode
      this.hasCreateBtn = false
      const paymentsClient = this.getGooglePaymentsClient()
      // console.time('onGooglePayLoaded')
      paymentsClient
        ?.isReadyToPay(this.getGoogleIsReadyToPayRequest())
        .then((response) => {
          // console.timeEnd('onGooglePayLoaded')
          // console.log(response);
          _this.valid = response.result ? 1 : 0
          if (_this.valid === 1) {
            createDom && _this.addGooglePayButton()
          }
          cb && cb(!!_this.valid)
          resolve(!!_this.valid)
        })
        .catch((err) => {
          // show error in developer console for debugging
          console.error(err)
          _this.valid = 0
          cb && cb(false)
          reject(false)
          apiReport.report({
            apiPath: '/googlepay-err-ready',
            payment_code: _this.paymentCode,
            err: err && JSON.stringify(err),
          })
        })
    })
  }

  addGooglePayButton() {
    if (this.hasCreateBtn) return
    const paymentsClient = this.getGooglePaymentsClient()
    const button = paymentsClient.createButton({ onClick: this.onGooglePaymentButtonClicked.bind(this) })
    const googleContent = document.getElementById('google-container')
    if (!googleContent) return
    googleContent.innerHTML = ''
    googleContent.appendChild(button)
    this.hasCreateBtn = true
  }

  //点击去支付按钮，弹出谷歌弹窗
  onGooglePaymentButtonClicked() {
    const _this = this
    sa('send', {
      page_name: 'page_checkout_again',
      activity_name: 'click_google_pay_popup_goto',
      activity_param: '',
    })

    if (typeof GooglePay.eventCenter !== 'undefined') {
      GooglePay.eventCenter.emit('isGooglePayLoading', 1)
    }

    const paymentDataRequest = _this.getGooglePaymentDataRequest()
    console.log('paymentDataRequest --', paymentDataRequest)
    if (typeof GooglePay.eventCenter !== 'undefined') {
      GooglePay.eventCenter.emit('cancelGooglePay')
    }

    // const paymentDataRequest = this.getGooglePaymentDataRequest();
    // orderAgainVue.cancelGoogle(true);
    // orderAgainVue.loading.show = true;

    const paymentsClient = _this.getGooglePaymentsClient()

    if (!paymentsClient) {
      _this._sendErrorReport('google_pay_sdk_error', {
        failure_type: 'sdk',
        client_url: '/third/sdk/error',
        description: 'googlePay paymentsClient找不到，调起sdk失败',
      })
    }

    paymentsClient
      .loadPaymentData(paymentDataRequest)
      .then(function (paymentData) {
        // console.log('验证成功-----', paymentDataRequest)
        //验证成功
        _this.processPayment(paymentData)
      })
      .catch(function (err) {
        // show error in developer console for debugging

        if (typeof GooglePay.eventCenter !== 'undefined') {
          GooglePay.eventCenter.emit('isGooglePayLoading', 0)
        }
        // orderAgainVue.loading.show = false;
        if (err && err.statusCode == 'CANCELED') {
          sa('send', {
            page_name: 'page_checkout_again',
            activity_name: 'click_google_pay_close',
            activity_param: '',
          })
          if (typeof GooglePay.eventCenter !== 'undefined') {
            GooglePay.eventCenter.emit('cancelGooglePay', {
              manualClose: true,
            })
          }
          _this._sendErrorReport('google_pay_pay_resultcode_cancel', {
            failure_type: 'web',
            client_url: '/third/sdk/error',
            description: 'googlePay从google支付第三方sdk页面返回时statusCode为 CANCELED',
          })
        } else {
          console.log(err)
          _this._sendErrorReport('google_pay_pay_resultcode_fail', {
            failure_type: 'sdk',
            client_url: '/third/sdk/error',
            description: 'googlePay从google支付第三方sdk页面返回时statusCode 为 ERROR',
          })
          if (typeof GooglePay.eventCenter !== 'undefined') {
            GooglePay.eventCenter.emit('errorGooglePay', {
              type: 'payError',
              text: _this.pay_fail_tips['w_7513'],
              extraInfo: {
                is_guide: 1
              }
            })
          }
          // blackMask.type = 'payError'
          // blackMask.showMask = true;
          // blackMask.text = "<%= language[lang].pay_lang.SHEIN_KEY_PC_15495 %>";

          // 除了用户主动取消外的异常上报
          apiReport.report({
            apiPath: '/googlepay-err',
            billno: _this.orderInfo.billno,
            payment_code: _this.paymentCode,
            err: err && JSON.stringify(err),
          })
        }
        // window.orderAgainVue && orderAgainVue.recordGooglePayErr && orderAgainVue.recordGooglePayErr(err)
      })
  }

  processPayment(paymentData) {
    const { host, langPath } = gbCommonInfo
    const _this = this
    // console.log('paymentData：', paymentData);
    const billno = this.orderInfo.billno
    const res = paymentData
    let token = res?.paymentMethodData?.tokenizationData?.token
    if (_this.paymentCode === 'nuvei-googlepay') {
      token = JSON.stringify(res?.paymentMethodData || {})
    }
    if (token) {
      const device_language = navigator.language || navigator.browserLanguage || ''

      const isGiftCard = ['GiftCard', 'GiftCardAgain'].includes(_this.scene)
      const isOCP = _this.scene === 'OCP'

      let checkout_type = _this._getCheckoutType()

      let cbUrl = '/pay/result/unifiedCb'
      let callbackUrl = host + langPath + cbUrl + '?bill_no=' + billno + '&checkout_type=' + checkout_type

      //兼容chrome第三方跳转不带cookie
      callbackUrl = host + '/transform/middlePage?callbackFormUrl=' + encodeURIComponent(host + langPath + cbUrl) + '&bill_no=' + billno + '&checkout_type=' + checkout_type

      let cancelUrl = callbackUrl
      let failureUrl = callbackUrl
      let pendingUrl = callbackUrl

      let url = '/pay/google'
      let formData = {
        billno: billno,
        token: token,
        device_language: device_language,
      }

      const sub_billnos = _this.orderInfo?.sub_billnos || []
      const subBillnos = sub_billnos?.map((item) => item.sub_billno) || []

      if (_this.abtGoUnify) {
        url = '/api/pay/unifiedPay/create'
        formData = {
          billno: billno,
          callbackUrl: callbackUrl,
          failureUrl: failureUrl,
          pendingUrl: pendingUrl,
          cancelUrl: cancelUrl,
          googlePayInfo: token,
          paymentCode: this.paymentCode,
          device_language: device_language,
          forterDeviceFingerprintID: window.forterDeviceId || '',
          forterSiteId: window.forterSiteId || '',
          riskifiedDeviceFingerprintID: window.riskifiedDeviceId || '',
          deviceFingerId: window.GB_cybersource_uuid || '',
          childBillnoList: subBillnos.join(','), // 子订单号
          checkout_type
        }

        // 同盾指纹
        if (window._fmOpt?.__blackbox) {
          formData.blackbox = window._fmOpt.__blackbox
        } else {
          // 未获取到同盾指纹上报
          apiReport.report({
            apiPath: 'tongdun/before-payment/error',
            sdk_init_status: window._fmOpt?.load_success ? 1 : 0, //同盾sdk初始化状态  1: 成功  0: 失败
            billno,
            paymentMethod: this.paymentCode
          })
        }

        if (window.forterSDKLoaded && !window.forterDeviceId) {
          //js初始化成功，且在发起支付时，前端给后端的指纹id为空
          apiReport.report({
            apiPath: `forter/before-payment/error`,
            scene: 'js sdk init success but forter deviceId is null',
            billno: billno,
            paymentMethod: this.paymentCode,
          })
        }
        if (!isGiftCard && !window.GB_cybersource_uuid) {
          // 非礼品卡，发起支付时，前端给后端的指纹id为空
          apiReport.report({
            apiPath: `cybs/before-payment/error`,
            scene: 'cybs deviceId is null',
            billno: billno,
            paymentMethod: this.paymentCode,
          })
        }
      }

      if (['adyen-googlepay', 'nuvei-googlepay'].includes(this.paymentCode)) {
        Object.assign(formData, this._getDeviceInfo() || {})
        if (this.paymentCode === 'nuvei-googlepay') {
          Object.assign(formData, {
            javaScriptEnabled: 1,
          })
        }
      }

      formData.neurPayId = billno + '_' + Math.floor(Math.random() * 99999)
      formData.neurStep = 1

      Object.assign(kafkaInfo, {
        neurStep: formData.neurStep,
        neurPayId: formData.neurPayId,
        startTime: +new Date(),
        callUrl: host + langPath + url,
        billno: billno,
        paymentMethod: _this.orderInfo?.payment_method,
      })

      if(this.routeId) {
        formData.routeId = this.routeId
      }

      if (!formData.googlePayInfo) {
        _this._sendErrorReport?.('google_pay_token_empty', {
          failure_type: 'api',
          client_url: '/pay/unifiedPay/create',
        })
      }

      schttp({
        url: url,
        method: 'POST',
        data: formData
      }).then(res => {
        kafkaInfo.nextHttpCode = 200
        kafkaInfo.nextResponse = JSON.stringify(res)
        kafkaInfo.kafkaAddDes({ formData: formData, res: res })
        kafkaInfo.sendData()

        if (res?.code != 0 || res?.info?.error_code) {
          _this._sendErrorReport?.('unified_pay_create_fail', {
            failure_type: 'api',
            client_url: '/pay/unifiedPay/create',
            status_code: res?.info?.error_code || res?.code,
            description: res?.info?.error_msg || res?.info?.show_error_msg
          })
        }

        if (res.code == 0 && res.info) {
          const extraInfo = {
            is_guide: res?.info?.is_guide,
            billno: billno,
            errorCode: res?.info?.error_code,
          }
          if (res.info.result == 1 && res.info.action == 'direct') {
            if (isGiftCard) {
              replacePaySuccessReferer({ isGiftCard: true })
              GB_route_jump(
                `${host}/giftcard/pay/result/success?billno=${billno}`
              )
              // GB_route_jump('<%=host%>/giftcard/pay/result/success?billno=' + billno)
            } else {
              if (isOCP) {
                GooglePay.eventCenter?.emit?.('successGooglePay', {
                  res
                })
                return
              }
              replacePaySuccessReferer()
              GB_route_jump(`${host}/pay/result/success?billno=${billno}`)
              // GB_route_jump('<%=host%>/pay/result/success?billno=' + billno)
            }
          } else if (res.info.action == 'render' && res.info.paramList) {
            makeForm(res.info.paramList, res.info.actionUrl)
          } else if (res.info.result == 0 && res.info.error_code) {
            // orderAgainVue.loading.show = false;
            // blackMask.text = res.info.show_error_msg || googlePayment.pay_fail_tips['w_' + res.info.error_code] || "<%= language[lang].pay_lang.SHEIN_KEY_PC_15495 %>";
            // blackMask.hasGuide = Number(res.info.is_guide) === 1
            // blackMask.type = 'payError'
            // blackMask.showMask = true;
            let analysisParams = {
              error_code: res.info.error_code,
              order_no: billno,
            }
            daEventCenter.triggerNotice({
              daId: '1-19-1-3',
              extraData: analysisParams,
            })
            // payTipPopPoints('1-19-1-3', analysisParams)

            let biData = analysisParams
            if (subBillnos?.length) {
              biData = Object.assign(
                {},
                { order_id_list: subBillnos.join(','), uorder_id: billno }
              )

            }

            if (typeof GooglePay.eventCenter !== 'undefined') {
              GooglePay.eventCenter.emit('errorGooglePay', {
                type: 'payError',
                text:
                  res.info.show_error_msg ||
                  _this.pay_fail_tips['w_' + res.info.error_code] ||
                  _this.pay_fail_tips['w_7513'],
                extraInfo,
                biData,
                res
              })
            }

            // blackMask.onOk = function () {
            //   blackMask.showMask = false;
            //   window.orderAgainVue.status.cardPay = ''
            //   window.orderAgainVue.status.error.nopayment = true
            //   window.orderAgainVue.status.error.txt = "<%= language[lang].checkout_lang.SHEIN_KEY_PC_18088 %>"
            //   window.orderAgainVue.scrollToPaymentMethod()

            //   payTipPopPoints('1-19-1-6', biData)
            // }
            // blackMask.cb = function (isClickClose) {
            //   if (isClickClose) {
            //     payTipPopPoints('1-19-1-5', biData)
            //   } else {
            //     payTipPopPoints('1-19-1-4')
            //   }
            // }
          }
        } else {
          if (typeof GooglePay.eventCenter !== 'undefined') {
            GooglePay.eventCenter.emit('isGooglePayLoading', 0)
          }
          _this._sendErrorReport('google_pay_pay_center_error', {
            failure_type: 'api',
            client_url: url,
            status_code: res?.code,
            description: 'googlePay调用统一支付接口接口报错',
          })
          if (res.code == '301002') {
            if (typeof GooglePay.eventCenter !== 'undefined') {
              GooglePay.eventCenter.emit('errorGooglePay', {
                type: '',
                text: _this.pay_fail_tips['w_301002'],
                res
              })
            }
            // blackMask.type = ''
            // blackMask.showMask = !blackMask.showMask
            // blackMask.text = "<%= language[lang].checkout_lang.SHEIN_KEY_PC_14787 %>"
          } else {
            if (typeof GooglePay.eventCenter !== 'undefined') {
              GooglePay.eventCenter.emit('errorGooglePay', {
                type: 'payError',
                text: _this.pay_fail_tips['w_7513'],
                res
              })
            }
            // blackMask.type = 'payError'
            // blackMask.showMask = true;
            // blackMask.text = "<%= language[lang].pay_lang.SHEIN_KEY_PC_15495 %>";
          }
        }
      })
        .catch((err) => {
          _this._sendErrorReport('google_pay_pay_center_try_error', {
            failure_type: 'api',
            client_url: url,
            status_code: err?.status || '',
            description: `googlePay调用统一支付接口接口发生异常被捕获 ${err?.message || ''}`,
          })
          if (typeof GooglePay.eventCenter !== 'undefined') {
            GooglePay.eventCenter.emit('errorGooglePay', {
              type: 'payError',
              text: _this.pay_fail_tips['w_7513'],
              res
            })
            GooglePay.eventCenter.emit('isGooglePayLoading', 0)
          }
          console.log(err)
          const resStatus = err.status || 0 //默认超时，传0
          kafkaInfo.nextResponse = JSON.stringify({})
          kafkaInfo.nextHttpCode = resStatus
          kafkaInfo.sendData()
        })
    }
  }

  isRelationBillno(billno = '') {
    if (!billno) return false
    return typeof billno?.startsWith === 'function' ? billno?.startsWith('U') : billno?.indexOf('U') === 0
  }

  getConfig({ code, cb }) {
    const _this = this
    this.originPaymentCode = code
    this.paymentCode = code
    const billno = _this.orderInfo.billno

    if(code != 'routepay-googlepay') {
      // 获取商户号
      let url = '/api/checkout/newGooglepayConfig/get'
      if (code == 'worldpay-googlepay') {
        url = '/api/checkout/googlepayConfig/get'
      }
      schttpSync({
        url: url,
        method: 'GET',
        params: { billno: billno, paymentCode: this.paymentCode },
        success: function (res) {
          if (res && res.code == 0 && res.info) {
            const { _apollo_pay_config, _apollo_google_pay_map } = res.info
            if (_apollo_pay_config && _apollo_pay_config[code]) {
              _this.payConfig = _apollo_pay_config[code]
            }
            if (_apollo_google_pay_map) {
              _this.cardLogoMap = _apollo_google_pay_map
            }

            _this.merchantId =
              res.info.googlepayMerchantId || res.info.merchantId || ''
            _this.gatewayMerchantId =
              res.info.worldpayMerchantId || res.info.gatewayMerchantId || ''
            _this.merchantName = res.info.brand || ''

            cb && cb()
          } else {
            _this._sendErrorReport('google_pay_config_error', {
              failure_type: 'api',
              client_url: url,
              status_code: res?.code,
              description: `获取gp配置信息失败 ${res?.tips || res.msg || ''}`,
            })
            try {
              sendFailApiReport(JSON.stringify(res || ''))
            } catch (e) { 
              console.log('获取gp配置信息失败e:', e)
            }
          }
        },
        error: function (e) {
          _this.configLoading = false
          sendFailApiReport(e?.message || '')
          _this._sendErrorReport('google_pay_config_error', {
            failure_type: 'api',
            client_url: url,
            status_code: e?.status || null,
            description: `获取gp配置信息失败 ${e?.message || ''}`,
          })
          if (typeof GooglePay.eventCenter !== 'undefined') {
            GooglePay.eventCenter.emit('errorGooglePay', {
              type: 'payError',
              text: _this.pay_fail_tips['w_7513'],
            })
          }
        },
      })
    } else {
      // 前置路由接口
      let params = {
        routeCode: _this.orderInfo?.payment_method
      }

      if (_this.isRelationBillno(_this.orderInfo?.billno)) {
        params.relationBillno = _this.orderInfo?.billno
      } else {
        params.billno = _this.orderInfo?.billno
      }
    
      if ('bin' in _this.orderInfo) {
        params.bin = _this.orderInfo?.bin
      }
    
      if ('tokenId' in _this.orderInfo) {
        params.tokenId = _this.orderInfo?.tokenId
      }
      schttpSync({
        url: '/api/checkout/payPreCardRouting/get',
        method: 'GET',
        params: params,
        success: function (res) {
          if (res && res.code == 0 && res.info) {
            _this.merchantId = res.info?.merchantInfo?.googleKey || ''
            _this.gatewayMerchantId = res.info?.merchantInfo?.googlePayMerchantId || ''
            _this.merchantName = res.info?.merchantInfo?.brand || ''
            _this.paymentCode = res.info?.payMethod
            _this.orderInfo.payment_method = res.info?.payMethod
            const { _apollo_pay_config, _apollo_google_pay_map } = res.info
            if (_apollo_pay_config && _apollo_pay_config[_this.paymentCode]) {
              _this.payConfig = _apollo_pay_config[_this.paymentCode]
            }
            if (_apollo_google_pay_map) {
              _this.cardLogoMap = _apollo_google_pay_map
            }
            _this.routeId = res.info?.routeId
            cb && cb()
          } else {
            _this._sendErrorReport('google_pay_config_error', {
              failure_type: 'api',
              client_url: '/api/checkout/payPreCardRouting/get',
              status_code: res?.code,
              description: `获取gp配置信息失败 ${res?.tips || res.msg || ''}`,
            })
            try {
              sendFailApiReport(JSON.stringify(res || ''))
            } catch (e) { 
              console.log('获取gp配置信息失败:', e)
            }
          }
        },
        error: function (e) {
          _this.configLoading = false
          sendFailApiReport(e?.message || '')
          _this._sendErrorReport('google_pay_config_error', {
            failure_type: 'api',
            client_url: '/api/checkout/payPreCardRouting/get',
            status_code: e?.status || null,
            description: `获取gp配置信息失败 ${e?.message || ''}`,
          })
          if (typeof GooglePay.eventCenter !== 'undefined') {
            GooglePay.eventCenter.emit('errorGooglePay', {
              type: 'payError',
              text: _this.pay_fail_tips['w_7513'],
            })
          }
        },
      })
    }

    
    function sendFailApiReport(msg) {
      apiReport.report({
        apiPath: 'googlepay/fail_to_get_info',
        billno,
        payment_code: code,
        errInfo: msg,
      })
    }
    
  }

  setOrderInfo(orderInfo) {
    // TODO: 调试信息，用完删除
    console.log('googlePay setOrderInfo', orderInfo)
    this.orderInfo = Object.assign({}, orderInfo)
  }

  setPaymentMethods(paymentMethods) {
    this.paymentMethods = paymentMethods
  }
}

export default GooglePay

